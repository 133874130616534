/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Bla } from '../models/Bla';
import type { BlaPartial } from '../models/BlaPartial';
import type { BlaRecordWithRelations } from '../models/BlaRecordWithRelations';
import type { BlaWithRelations } from '../models/BlaWithRelations';
import type { NewBla } from '../models/NewBla';
import type { UserSubscriptionsWithRelations } from '../models/UserSubscriptionsWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BlaControllerService {
  /**
   * @returns UserSubscriptionsWithRelations Array of Bla model instance
   * @throws ApiError
   */
  public static blaControllerCreateBulk({
    userSubscriptionId,
    requestBody,
  }: {
    userSubscriptionId?: string;
    requestBody?: Array<NewBla>;
  }): CancelablePromise<UserSubscriptionsWithRelations> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/bla/bulk',
      query: {
        userSubscriptionId: userSubscriptionId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Bla model count
   * @throws ApiError
   */
  public static blaControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/bla/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static blaControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: Bla;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/bla/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static blaControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: BlaPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/bla/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns BlaWithRelations Bla model instance
   * @throws ApiError
   */
  public static blaControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<BlaWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/bla/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static blaControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/bla/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Bla Bla model instance
   * @throws ApiError
   */
  public static blaControllerCreate({
    requestBody,
  }: {
    requestBody?: NewBla;
  }): CancelablePromise<Bla> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/bla',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Bla PATCH success count
   * @throws ApiError
   */
  public static blaControllerUpdateAll({
    where,
    requestBody,
  }: {
    where?: any;
    requestBody?: BlaPartial;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/bla',
      query: {
        where: where,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Array of Bla model instances
   * @throws ApiError
   */
  public static blaControllerFind({
    filter,
    coachId,
    page,
  }: {
    filter?: any;
    coachId?: string;
    page?: number;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<BlaWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/bla',
      query: {
        filter: filter,
        coachId: coachId,
        page: page,
      },
    });
  }

  /**
   * @returns BlaRecordWithRelations BlaRecord model instances
   * @throws ApiError
   */
  public static blaControllerBlaRecordById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<BlaRecordWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/bla-record/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns any Array of BlaRecord model instances
   * @throws ApiError
   */
  public static blaControllerFindAllBlaRecord({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<BlaRecordWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/bla-record',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
