/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Coach } from '../models/Coach';
import type { CoachPartial } from '../models/CoachPartial';
import type { CoachProgramsWithRelations } from '../models/CoachProgramsWithRelations';
import type { CoachSpecialityWithRelations } from '../models/CoachSpecialityWithRelations';
import type { CoachWithRelations } from '../models/CoachWithRelations';
import type { NewCoach } from '../models/NewCoach';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CoachControllerService {
  /**
   * @returns any Member Migration instance
   * @throws ApiError
   */
  public static coachControllerAssignCoachMemberMigration(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/assignCoachMemberMigration',
    });
  }

  /**
   * @returns CoachSpecialityWithRelations Array of Coach model instances
   * @throws ApiError
   */
  public static coachControllerFindByAllCoachesSpecialityId({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<Array<CoachSpecialityWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/coachBySpeciality/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns any Coaches password change feature
   * @throws ApiError
   */
  public static coachControllerChangePassword({
    requestBody,
  }: {
    requestBody?: any;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/coaches/change-password',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Coach model count
   * @throws ApiError
   */
  public static coachControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/coaches/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns CoachWithRelations Coach model instance
   * @throws ApiError
   */
  public static coachControllerGetCoachesList({
    requestBody,
  }: {
    requestBody?: any;
  }): CancelablePromise<CoachWithRelations> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/coaches/getCoachesList',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Coach Invite instance
   * @throws ApiError
   */
  public static coachControllerInviteCoach({
    requestBody,
  }: {
    requestBody?: any;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/coaches/invite',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Token
   * @throws ApiError
   */
  public static coachControllerLogin({
    requestBody,
  }: {
    /**
     * The input of login function
     */
    requestBody: {
      email: string;
      password: string;
      source?: 'mobile_app' | 'web';
      browserAgent?: string;
    };
  }): CancelablePromise<{
    token?: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/coaches/login',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Logout
   * @throws ApiError
   */
  public static coachControllerLogout({
    requestBody,
  }: {
    requestBody?: {
      source?: 'mobile_app' | 'web';
      browserAgent?: string;
    };
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/coaches/logout',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns CoachProgramsWithRelations Array of CoachProgram model instances
   * @throws ApiError
   */
  public static coachControllerGetCoachPrograms({
    id,
  }: {
    id: string;
  }): CancelablePromise<Array<CoachProgramsWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/coaches/programs/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns any token refreshed
   * @throws ApiError
   */
  public static coachControllerRefreshCoachToken({
    token,
  }: {
    token?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/coaches/refresh-token',
      query: {
        token: token,
      },
    });
  }

  /**
   * @returns any Member model instance
   * @throws ApiError
   */
  public static coachControllerRequestPasswordReset({
    requestBody,
  }: {
    requestBody?: any;
  }): CancelablePromise<{
    token?: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/coaches/reset',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Coaches model instance
   * @throws ApiError
   */
  public static coachControllerResetPassword({
    requestBody,
  }: {
    requestBody?: any;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/coaches/reset-password',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Member model instance
   * @throws ApiError
   */
  public static coachControllerSendForgotUrl({
    requestBody,
  }: {
    requestBody?: any;
  }): CancelablePromise<{
    token?: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/coaches/sendForgotUrl',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Member model instance
   * @throws ApiError
   */
  public static coachControllerSendOtp({ requestBody }: { requestBody?: any }): CancelablePromise<{
    token?: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/coaches/sendOtp',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Coach PATCH success
   * @throws ApiError
   */
  public static coachControllerUpdateCoach({
    id,
    newCoachId,
    isActive,
    requestBody,
  }: {
    id: string;
    newCoachId?: string;
    isActive?: boolean;
    requestBody?: CoachPartial;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/coaches/status/{id}',
      path: {
        id: id,
      },
      query: {
        newCoachId: newCoachId,
        isActive: isActive,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Verify the coach OTP
   * @throws ApiError
   */
  public static coachControllerVerifyOtp({
    requestBody,
  }: {
    requestBody?: any;
  }): CancelablePromise<{
    email?: any;
    otp?: any;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/coaches/verifyOtp',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static coachControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: Coach;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/coaches/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static coachControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: CoachPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/coaches/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns CoachWithRelations Coach model instance
   * @throws ApiError
   */
  public static coachControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<CoachWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/coaches/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static coachControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/coaches/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Cannot delete an active coach`,
        404: `Coach not found`,
      },
    });
  }

  /**
   * @returns Coach Coach model instance
   * @throws ApiError
   */
  public static coachControllerCreate({
    requestBody,
  }: {
    requestBody?: NewCoach;
  }): CancelablePromise<Coach> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/coaches',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Coach PATCH success count
   * @throws ApiError
   */
  public static coachControllerUpdateAll({
    where,
    requestBody,
  }: {
    where?: any;
    requestBody?: CoachPartial;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/coaches',
      query: {
        where: where,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns CoachWithRelations Array of Coach model instances
   * @throws ApiError
   */
  public static coachControllerFind({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<CoachWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/coaches',
      query: {
        filter: filter,
        page: page,
      },
    });
  }

  /**
   * @returns any Member Migration instance
   * @throws ApiError
   */
  public static coachControllerCoachesMigration(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/coachesMigration',
    });
  }

  /**
   * @returns any Array of paginated Coach model instances
   * @throws ApiError
   */
  public static coachControllerGetAllCoaches({
    limit,
    search,
    page,
    isActive,
  }: {
    limit?: number;
    search?: string;
    page?: number;
    isActive?: boolean;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/getAllCoaches',
      query: {
        limit: limit,
        search: search,
        page: page,
        isActive: isActive,
      },
    });
  }

  /**
   * @returns any Array of paginated Coach model instances
   * @throws ApiError
   */
  public static coachControllerGetCoachesBySpecialty({
    query,
  }: {
    query?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/getCoachesBySpecialty',
      query: {
        query: query,
      },
    });
  }
}
