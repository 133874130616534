import { useGetRequest } from 'api/useGetRequest';
import { OutlierControllerService } from 'generated';

interface UseGetOutlierStatisticsProps {
  callback?: () => void;
  subscribedMembers?: boolean;
  coachId?: string;
}

const useGetOutlierStatistics = ({
  callback,
  subscribedMembers = false,
  coachId,
}: UseGetOutlierStatisticsProps = {}) => {
  const { data, loading, refetch } = useGetRequest({
    service: OutlierControllerService.outlierControllerOutlierStatistics,
    tag: 'outlierControllerOutlierStatistics',
    payload: {
      subscribedMembers: subscribedMembers ? 'true' : undefined,
      reviewerId: coachId,
    },
    onSuccess: () => {
      callback?.();
    },
  });

  return {
    outlierStats: data,
    outlierStatsLoading: loading,
    refetch,
  };
};

export default useGetOutlierStatistics;
