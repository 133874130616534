import { usePostHog } from 'posthog-js/react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

const PostHogPageView = () => {
  const postHog = usePostHog();

  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (pathname) {
      let url = window?.origin + pathname;
      const searchParamsValue = searchParams?.toString();

      if (searchParamsValue) {
        url = url + '?' + searchParamsValue;
      }
      postHog.capture('$pageview', { $current_url: url });
    }
  }, [pathname, searchParams, postHog]);

  return null;
};

export default PostHogPageView;
