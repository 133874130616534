import MuiDrawer from 'components/Drawer/MUIDrawer';
import EmptyState from 'components/EmptyState/EmptyState';
import useTabsComponent from 'components/TabsComponent/useTabsComponent';
import { useMarkNotificationAsRead } from 'general-hooks/notifications/useMarkNotificationAsRead';
import { Notification } from 'generated';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { getExternalLink, Notify } from 'utils/helpers';
import useBreakpointValue from 'utils/useBreakpointValue/useBreakpointValue';

interface Props {
  isOpen?: boolean;
  onClose: () => void;
}

const UnreadNotificationsDrawer = ({ isOpen, onClose }: Props) => {
  const { unreadNotificationCount, unreadNotifications } = useAppSelector(
    (state) => state.notification,
  );

  const width = useBreakpointValue({
    base: '80vw',
    md: '40vw',
    lg: '30vw',
    xl: '25vw',
  });

  const { setActiveIndex, activeIndex } = useTabsComponent({});

  return (
    <MuiDrawer isOpen={isOpen} onClose={() => onClose()} title="Notifications" width={width}>
      <div className="overflow-y-scroll max-h-screen pt-4">
        <div>
          <NotificationList items={unreadNotifications} closeDrawer={onClose} />
        </div>
      </div>
    </MuiDrawer>
  );
};

const NotificationList: React.FC<{ items?: Notification[]; closeDrawer?: () => void }> = ({
  items,
  closeDrawer,
}) => {
  const navigate = useNavigate();

  const { trigger } = useMarkNotificationAsRead();

  const handleNotificationClick = (item: Notification) => {
    trigger({ id: item?.id, requestBody: { isSeen: true } });
    switch (item?.notificationType) {
      case 'CHAT':
        navigate('/chat', { state: { chatRoom: item?.notificationObject?.chatRoom } });
        break;

      case 'GENERAL':
        navigate('/');
        break;

      case 'NEW_CONSULT':
        navigate(`/consults/${item?.notificationObject?.id}`);
        break;

      case 'NEW_BLOG_POST':
        navigate('/');
        break;

      case 'NEW_SUBSCRIPTION':
        navigate(
          `/members/${item?.notificationObject?.userId}/${item?.notificationObject?.programId}`,
        );
        break;

      case 'REMINDER':
        navigate('/appointments', { state: { appointment: item?.notificationObject } });
        break;

      default:
        break;
    }
  };

  const openMeetingLink = (url) => {
    if (!url) {
      return;
    }
    const link = getExternalLink(url);

    return window.open(link, '_blank');
  };

  return (
    <div className="flex flex-col gap-2">
      {items?.length > 0 ? (
        items?.map((item) => (
          <div
            onClick={() => {
              handleNotificationClick(item);
              closeDrawer?.();
            }}
            className={`w-full border border-gray-400/20 rounded-lg p-4 cursor-pointer relative group ${
              item?.isSeen ? 'bg-white' : 'bg-gray-400/5'
            }`}
          >
            <div className="flex items-center gap-4">
              <div className="w-10 h-10 rounded-full flex items-center justify-center bg-gray-400/10 overflow-hidden">
                {item?.image ? (
                  <img
                    src={item?.image}
                    alt="Notification"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <i className="mDoc-notification text-lg" />
                )}
              </div>
              <div className="w-[70%]">
                <div>
                  <p className="text-sm text-black font-medium truncate line-clamp-1">
                    {item?.title}
                  </p>
                  <p className="text-gray-400 text-xs truncate line-clamp-2">{item?.body}</p>
                </div>
              </div>
            </div>
            {item?.notificationType === 'ACCEPTED_CONSULT' ? (
              <div className="flex flex-col items-end justify-end mt-2">
                <div
                  className="bg-cardOrange/30 text-cardOrange rounded-full px-4 py-2"
                  onClick={(e) => {
                    if (!item?.notificationObject?.meetingResource) {
                      Notify.info('Invalid meeting link or meeting link expired');
                      e.stopPropagation();
                    } else {
                      openMeetingLink(item?.notificationObject?.meetingResource);
                    }
                  }}
                >
                  <p className="text-xs">Open Meeting Link</p>
                </div>
              </div>
            ) : null}
            {!item?.isSeen ? (
              <div className="absolute right-2 top-2 w-2 h-2 rounded-full bg-cardOrange" />
            ) : null}
          </div>
        ))
      ) : (
        <div>
          <EmptyState
            height="400px"
            fullText
            title="No unread notifications"
            description="You have read all your notifications."
          />
        </div>
      )}
    </div>
  );
};

export default UnreadNotificationsDrawer;
