/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewSymptomHealthCondition } from '../models/NewSymptomHealthCondition';
import type { SymptomHealthCondition } from '../models/SymptomHealthCondition';
import type { SymptomHealthConditionPartial } from '../models/SymptomHealthConditionPartial';
import type { SymptomHealthConditionWithRelations } from '../models/SymptomHealthConditionWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SymptomHealthConditionControllerService {
  /**
   * @returns any SymptomHealthCondition model count
   * @throws ApiError
   */
  public static symptomHealthConditionControllerCount({
    where,
  }: {
    where?: any;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/symptom-health-conditions/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static symptomHealthConditionControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: SymptomHealthCondition;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/symptom-health-conditions/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static symptomHealthConditionControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: SymptomHealthConditionPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/symptom-health-conditions/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns SymptomHealthConditionWithRelations SymptomHealthCondition model instance
   * @throws ApiError
   */
  public static symptomHealthConditionControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<SymptomHealthConditionWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/symptom-health-conditions/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static symptomHealthConditionControllerDeleteById({
    id,
  }: {
    id: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/symptom-health-conditions/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns SymptomHealthCondition SymptomHealthCondition model instance
   * @throws ApiError
   */
  public static symptomHealthConditionControllerCreate({
    requestBody,
  }: {
    requestBody?: NewSymptomHealthCondition;
  }): CancelablePromise<SymptomHealthCondition> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/symptom-health-conditions',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any SymptomHealthCondition PATCH success count
   * @throws ApiError
   */
  public static symptomHealthConditionControllerUpdateAll({
    where,
    requestBody,
  }: {
    where?: any;
    requestBody?: SymptomHealthConditionPartial;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/symptom-health-conditions',
      query: {
        where: where,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns SymptomHealthConditionWithRelations Array of SymptomHealthCondition model instances
   * @throws ApiError
   */
  public static symptomHealthConditionControllerFind({
    filter,
  }: {
    filter?: any;
  }): CancelablePromise<Array<SymptomHealthConditionWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/symptom-health-conditions',
      query: {
        filter: filter,
      },
    });
  }
}
