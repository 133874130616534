/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MedicationPassportPartialExcluding_created_coachId_userId_consultId_dependentId_goalActionId_hubVisitId_ } from '../models/MedicationPassportPartialExcluding_created_coachId_userId_consultId_dependentId_goalActionId_hubVisitId_';
import type { NewMedicationPassport } from '../models/NewMedicationPassport';
import type { NewPrescription } from '../models/NewPrescription';
import type { Prescription } from '../models/Prescription';
import type { PrescriptionPartialExcluding_id_created_at_medicationPassports_coachId_consultId_userId_dependentId_ } from '../models/PrescriptionPartialExcluding_id_created_at_medicationPassports_coachId_consultId_userId_dependentId_';
import type { PrescriptionWithRelations } from '../models/PrescriptionWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PrescriptionControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static prescriptionControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: {
      prescription?: PrescriptionPartialExcluding_id_created_at_medicationPassports_coachId_consultId_userId_dependentId_;
      medicationPassports?: Array<MedicationPassportPartialExcluding_created_coachId_userId_consultId_dependentId_goalActionId_hubVisitId_>;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/prescriptions/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns PrescriptionWithRelations Prescription model instance
   * @throws ApiError
   */
  public static prescriptionControllerFindById({
    id,
  }: {
    id: string;
  }): CancelablePromise<PrescriptionWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/prescriptions/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static prescriptionControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/prescriptions/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Prescription Prescription
   * @throws ApiError
   */
  public static prescriptionControllerCreate({
    requestBody,
  }: {
    requestBody?: {
      prescription?: NewPrescription;
      medicationPassports?: Array<NewMedicationPassport>;
    };
  }): CancelablePromise<Prescription> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/prescriptions',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Array of Prescription model instances
   * @throws ApiError
   */
  public static prescriptionControllerFind({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<PrescriptionWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/prescriptions',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
