/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewReferral } from '../models/NewReferral';
import type { Referral } from '../models/Referral';
import type { ReferralPartial } from '../models/ReferralPartial';
import type { ReferralWithRelations } from '../models/ReferralWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReferralControllerService {
  /**
   * @returns any
   * @throws ApiError
   */
  public static referralControllerUploadReferralSlip({
    formData,
  }: {
    /**
     * multipart/form-data value.
     */
    formData: any;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/referral-slips',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * @returns any Coach referral statistics
   * @throws ApiError
   */
  public static referralControllerGetCoachStatistics(): CancelablePromise<{
    totalReferrals?: number;
    totalOutboundReferrals?: number;
    totalPendingReferrals?: number;
    totalAcceptedRefferals?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/referrals/coach-statistics',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static referralControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: ReferralPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/referrals/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ReferralWithRelations Referral model instance
   * @throws ApiError
   */
  public static referralControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<ReferralWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/referrals/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static referralControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/referrals/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Referral Referral model instance
   * @throws ApiError
   */
  public static referralControllerCreate({
    requestBody,
  }: {
    requestBody?: NewReferral;
  }): CancelablePromise<Referral> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/referrals',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Array of Referral model instances
   * @throws ApiError
   */
  public static referralControllerFind({
    filter,
    page,
    search,
    userFullNameSort,
    referralReasonSort,
    createdAtSort,
    referralStatusSort,
    referralTypeSort,
    reasonTypeSort,
    acceptanceStatusSort,
  }: {
    filter?: any;
    page?: number;
    search?: string;
    userFullNameSort?: string;
    referralReasonSort?: string;
    createdAtSort?: string;
    referralStatusSort?: string;
    referralTypeSort?: string;
    reasonTypeSort?: string;
    acceptanceStatusSort?: string;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<ReferralWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/referrals',
      query: {
        filter: filter,
        page: page,
        search: search,
        userFullNameSort: userFullNameSort,
        referralReasonSort: referralReasonSort,
        created_atSort: createdAtSort,
        referralStatusSort: referralStatusSort,
        referralTypeSort: referralTypeSort,
        reasonTypeSort: reasonTypeSort,
        acceptanceStatusSort: acceptanceStatusSort,
      },
    });
  }
}
