interface ModalFooterProps {
  children: any;
  onSaveAndAdd?: (i: boolean) => void;
  saveAndAdd?: boolean;
  saveAndAddText?: string;
  checkSave?: boolean;
  toggle?: () => void;
}

const ModalFooter = ({
  children,
  checkSave,
  onSaveAndAdd,
  saveAndAddText,
  saveAndAdd,
  toggle,
}: ModalFooterProps) => {
  return (
    <section
      className={`sticky bottom-0 flex p-4 py-5 space-x-2 mt-4 border-t border-t-[#ECECEC] bg-[#FAFAFA] items-center ${
        saveAndAdd ? 'justify-between' : 'justify-end'
      }`}
    >
      {saveAndAdd ? (
        <div>
          <label className="flex items-center cursor-pointer text-sm" aria-checked={checkSave}>
            <input
              type="checkbox"
              checked={checkSave}
              onChange={(e) => onSaveAndAdd(e.target.checked)}
              className="mr-2"
            />
            {saveAndAddText ?? 'Save & add another'}
          </label>
        </div>
      ) : null}
      <div className="flex items-center justify-end gap-4">{children}</div>
    </section>
  );
};

export default ModalFooter;
