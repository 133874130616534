/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewNotification } from '../models/NewNotification';
import type { Notification } from '../models/Notification';
import type { NotificationPartial } from '../models/NotificationPartial';
import type { NotificationPartialExcluding_id_adminId_coachId_userId_created_title_body_notificationType_priority_notificationObject_resourceId_image_ } from '../models/NotificationPartialExcluding_id_adminId_coachId_userId_created_title_body_notificationType_priority_notificationObject_resourceId_image_';
import type { NotificationWithRelations } from '../models/NotificationWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotificationControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static notificationControllerUpdateAllNotificationsStatus({
    requestBody,
  }: {
    requestBody?: {
      notifications?: NotificationPartialExcluding_id_adminId_coachId_userId_created_title_body_notificationType_priority_notificationObject_resourceId_image_;
      ids?: Array<string>;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/all-notifications-status',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Notification model count
   * @throws ApiError
   */
  public static notificationControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/notifications/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns NotificationWithRelations Array of Notification model instances
   * @throws ApiError
   */
  public static notificationControllerGetAllNotificationByUserId({
    userId,
  }: {
    userId: string;
  }): CancelablePromise<Array<NotificationWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/notifications/userId/{userId}',
      path: {
        userId: userId,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static notificationControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: Notification;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/notifications/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static notificationControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: NotificationPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/notifications/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns NotificationWithRelations Notification model instance
   * @throws ApiError
   */
  public static notificationControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<NotificationWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/notifications/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static notificationControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/notifications/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Notification Notification model instance
   * @throws ApiError
   */
  public static notificationControllerCreate({
    requestBody,
  }: {
    requestBody?: NewNotification;
  }): CancelablePromise<Notification> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/notifications',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Notification PATCH success count
   * @throws ApiError
   */
  public static notificationControllerUpdateAll({
    where,
    requestBody,
  }: {
    where?: any;
    requestBody?: NotificationPartial;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/notifications',
      query: {
        where: where,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns NotificationWithRelations Array of Notification model instances
   * @throws ApiError
   */
  public static notificationControllerFind({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<NotificationWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/notifications',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
